@import "normalize.css/normalize.css";

body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "sansserif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-image: url("./assets/layer.png");
  background-color: rgb(12, 0, 20);
  background-size: cover;
  background-position: center;
  overflow-x:hidden;
  overflow-y:initial;
}
::-webkit-scrollbar {
  width: 0.2vw;
}

/* Изменение цвета самого скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #281e35; /* Чуть темнее серого цвета для палки прокрутки */
  border-radius: 1vw; /* Закругленные края */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5); /* Немного более темная тень */
}

/* Изменение цвета фона скроллбара */
::-webkit-scrollbar-track {
  background-color: transparent; /* Прозрачный фон */
}

@media (max-width: 1025px) {
  body {
    background-image: url("./assets/layerMob.png");
    background-size: cover;
    background-position: center;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Neue Machina";
  src: url("./assets/fonts/Neue Machina/NeueMachina-Light.otf")
    format("opentype");
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: "Neue Machina";
  src: url("./assets/fonts/Neue Machina/NeueMachina-Regular.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue Machina";
  src: url("./assets/fonts/Neue Machina/NeueMachina-Ultrabold.otf")
    format("opentype");
  font-weight: 800;
  font-style: bold;
}
