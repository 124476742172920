.navbar {
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr 9fr;
  gap: 15px;
  color: white;
  height: 3.8vw;
  flex-wrap: initial;
  z-index: 4;
  align-content: center;
  margin-bottom: -1.5vw;
  margin: 0 13%;
}

.logo {
  width: 3.07291667vw;
  height: max-content;
  cursor: pointer;
}

.navbar .navbar-menu {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: end;
  flex-shrink: 0;
  align-content: flex-end;
  align-items: center;
}

.navbar-menu a {
  margin-right: 0.868055556vw;
  font-size: 0.78125vw;
  cursor: pointer;
}

.navbar-contact {
  text-decoration: bold;
  color: #fff;
  border-radius: 2.31481481vw;
  border: 0.025vw solid #9f3dff;
  width: 11.46vw;
  height: 2.39vw;
  background: linear-gradient(90deg, #52188b 3%, #9f3dff 50%, #52188b 97.66%);
  justify-content: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Neue Machina;
  font-size: 0.67vw;
  font-weight: 500;
  cursor: pointer;
  line-height: 0;
}

.navbar-contact .text {
  /* text-shadow:
    3px 5px 4px rgba(0, 0, 0, 0.9),
    -1px 1px 0px rgba(0, 0, 0, 1),
    1px 1px 0px rgba(0, 0, 0, 1),
    1px -1px 0px rgba(0, 0, 0, 1),
    -1px -1px 0px rgba(0, 0, 0, 1); */
}
.mobile_img2 {
  display: none;
}
.navbar-contact:hover {
  background: linear-gradient(90deg, #9f3dff 3%, #52188b 50%, #9f3dff 97.66%);
}

@media (max-width: 1025px) {
  .navbar {
    margin: 0 8%;
    margin-top: 1vw;
    height: 5.1vw;
  }
  .logo {
    width: 4.07291667vw;
    height: max-content;
    cursor: pointer;
  }
  .navbar-menu a {
    margin-right: 1vw;
    font-size: 1.2vw;
  }
  .navbar-contact {
    width: 18vw;
    height: 4vw;
    font-size: 1vw;
    line-height: normal;
  }
}
@media (max-width: 431px) {
  .navbar {
    position: relative;
    margin: 0;
    margin-top: 1vw;
    padding: 0 4%;
    background-color: #52188b00;
    height: 12vw;
  }
 
  .logo {
    margin-top: 1vw;
    width: 11vw;
    height: max-content;
    cursor: pointer;
  }
  .navbar-menu a {
    display: none;
    margin-right: 4vw;
    font-size: 1.8vw;
  }
  .navbar-contact {
    text-wrap: nowrap;
    border-radius: 7vw;
    width: 56vw;
    height: 10vw;
    font-size: 3vw;
    text-align: center;
    padding-top: 0.4vw;
  }
}
