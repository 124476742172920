.hero-section {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-left: 0;
  color: #fff;
  z-index: 1;
  max-height: fit-content;
  margin-left: 13%;
}

.hero-section .cont {
  display: flex;
  position: relative;
  height: 100%;
  justify-items: end;
  overflow: hidden;
}

.hero-section .hero-text {
  padding-top: 10vw;
  position: static;
  width: fit-content;
  z-index: 1;
}

.hero-section h1 {
  font-family: "Neue Machina", sans-serif;
  font-size: 3.1vw;
  font-weight: 800;
  line-height: 101%;
  text-transform: uppercase;
  background: linear-gradient(
    270deg,
    #b9bebe 0.16%,
    #d8dbdb 48.49%,
    #fff 99.94%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  word-break: keep-all;
  line-height: 1.2;
  margin: 0 auto;
}

h1.first {
  text-transform: uppercase;
  background: linear-gradient(270deg, #b9bebe 0.16%, #fff 48.49%, #fff 99.94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.hero-section p {
  max-width: 50vw;
  color: #adabb2;
  font-size: 1.05vw;
  font-family: Neue Machina;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.02vw;
}

.hero_img {
  position: relative;
  width: max-content;
  height: 40vw;
  z-index: 1;
  margin-left: -4vw;
  z-index: 3;
}

.wetake {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 2.5vw;
  width: max-content;
  height: 1.7vw;
  cursor: pointer;
}

.hero-section .cta-button:hover {
  background: linear-gradient(267.79deg, #c66300 0%, #cc3700 99.18%);
}
.mobile_img {
  display: none;
}
@media (max-width: 1025px) {
  .hero-section {
    grid-template-columns: 1fr;
    margin-left: 7%;
  }

  .hero-section .hero-text {
    padding-top: 12vw;
    z-index: 1;
  }

  .hero-section h1 {
    width: 80vw;
    font-size: 3.7vw;
    word-break: keep-all;
    line-height: 1.2;
    margin: 0;
  }
  h1 .second {
    word-wrap: break-word;
    text-wrap: wrap;
  }
  .hero-section p {
    text-wrap: stable;
    max-width: 58vw;
    font-size: 1.2vw;
    line-height: 125%;
    letter-spacing: 0.06vw;
  }

  .hero_img {
    display: none;
  }
  .mobile_hero_next {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .mobile_img {
    display: flex;
    position: relative;
    width: max-content;
    height: 45vw;
    width: auto;
    margin-left: -5vw;
    margin-top: -20vw;
    margin-right: -10vw;
  }

  .wetake {
    margin-top: 2.5vw;
    height: 2vw;
  }
  .text_section p{
    width: 92vw;
  }
}
@media (max-width: 431px) {
 .hero-text{
  margin-top: -15vw;
 }
  .mobile_hero_next {
    display: flex;
    height: auto;
  }
  .mobile_img {
    display: none;
    width: 0;
    
  }
  .mobile_img2 {
    display: flex;
    width: 107vw;
    height: auto;
    margin-bottom: -7vw;

  }


  .hero-section {
    display: flex;
    margin-left: 5vw;
    margin-right:4vw;
    margin-bottom: 20vw;
  }
  .text_section .first {
    width: 105vw;
    margin-top: 3vw;
    margin-bottom: 2vw;
    display: flex;
    flex-direction: column;
    
  }
  .text_section {
    display: flex;
    flex-direction: column;
    padding: 0;

  }
  .hero-section h1 {
    font-size: 5.8vw;
    width: 95vw;
    line-height: 1.2;
    margin: 0;
    
  }

  .hero-section p {
    text-wrap: wrap;
    font-size: 4vw;
    margin-top: 0.2vw;
    max-width: 93vw;
    
  }
  .wetake {
    height: 5.8vw;
    width: auto;
    justify-self: start;
    margin-left: -10vw;
  }
}
