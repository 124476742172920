.purchaseCont {
  font-family: "Neue Machina", sans-serif;
  margin-top: 9vw;
  margin-bottom: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  font-size: 1vw;
  grid-row: 1;
}
.purchaseCont .dropdown-header {
  width: max-content;
  justify-content: start;
  align-items: center;
  display: flex;
  margin-top: 0.1vw;
}
.purchse_head h1 {
  display: grid;
  font-size: 1.7vw;
  font-weight: 400;
}
.purchse_head .pay {
  margin-top: 3vw;
}
.purchaseCont .dropdown-question2 {
  padding: 0;
  margin: 0;
}
.purchaseCont {
  flex-direction: column;
  padding: auto 2vw;
  width: auto;
}
.purchaseCont .pricecont {
  margin: 0 2vw;
}

.purchaseCont .dropname {
  width: max-content;
  display: inline;
  margin: 0;
}
.payCont {
  display: flex;
  flex-direction: row;
  margin-bottom: 5vw;
}
.payCont .svg {
  height: 2.8vw;
  width: auto;
  margin: 0 0.5vw;
  cursor: pointer;
  transition: fill 0.3s ease;
}

.svg-hovered .svg1 rect {
  transition: fill 0.5s ease;
  fill: #9f3dff;
}
.svg-hovered .svg2 rect {
  transition: fill 0.5s ease;
  fill: #9f3dff;
}
.svg-hovered .svg3 rect {
  transition: fill 0.5s ease;
  fill: #9f3dff;
}
.svg-hovered .svg4 rect {
  transition: fill 0.5s ease;
  fill: #9f3dff;
}
.modal-content {
  display: flex;
  flex-direction: column;
}
.modal-content button {
  margin-top: 1vw;
  font-size: 1vw;
}
.form-container {
  position: relative;
  margin-right: -50vw;
  margin-left: -7vw;
  max-width: 30vw;
}


@media (max-width: 431px) {
  .purchaseCont .dropdown-header {
    justify-content: center;
    align-items: center;
    max-height: fit-content;
    height: 15vw;
    width: 94vw;
    padding: 0;
  }
  .priceP1 {
    margin: 0;
    font-size: 4vw;
  }
  .purchaseCont {
    margin-top: 24vw;
  }
  .purchaseCont .dropname {
    display: flex;
    width: 100%;
    word-wrap: break-word;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2vw;
  }
  .dropdown-question1 {
    justify-content: center;
    align-items: center;
  }
  .purchaseCont .dropdown-question1 {
    font-size: 5vw;
  }
  .purchaseCont .dropdown-question2 {
    font-size: 4vw;
    word-wrap: break-word;
    flex-wrap: wrap;
    white-space: pre-line;
    text-align: center;
  }
  .purchaseCont .pricecont {
    display: flex;
    justify-content: center;
    height: fit-content;
    margin-top: 5vw;
    font-size: 4vw;
    text-align: center;
    align-self: center;
  }
  .purchaseCont .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .purchse_head h1 {
    text-transform: uppercase;
    display: grid;
    font-size: 6vw;
    font-weight: 600;
  }
  .payCont .svg {
    height: 8.5vw;
    width: auto;
    margin: 0 1.9vw;
    cursor: pointer;
    transition: fill 0.3s ease;
  }
  .purchse_head .pay {
    text-transform: none;
    font-weight: 400;
    margin-top: 6vw;
    margin-bottom: 4vw;
    font-size: 4vw;
  }
}
