.dropdown {
  display: flex;
  width: 74.17vw;
  margin-left: 5vw;
  font-family: "Neue Machina";
}

.dropdown-question1 {
  margin-left: 1.3vw;
  margin-right: 0.5vw;
  color: #fff;
  font-size: 1.5625vw;
  font-family: "Neue Machina";
  font-style: normal;
}

.dropdown-question2 {
  color: #adabb2;
  font-size: 1vw;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  margin-right: -4vw;
}

.dropname {
  flex-wrap: nowrap;
  line-height: 1.45vw;
  display: flex;
}

.dropdown-header {
  background-color: #330f57;
  border: 0.025vw solid #9f3dff;
  width: 75.5vw;
  height: 4.4vw;
  padding: auto;
  border-radius: 1vw;
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  margin-top: 1.1vw;
  margin-bottom: 1.1vw;
}

.dropdown-body {
  font-family: "Neue Machina";
  font-size: 1.0417vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5625vw;
  text-align: left;
  max-height: 0;
  width: 72.9167vw;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease, opacity 0.5s ease;
  opacity: 0;
}

.dropdown.open .dropdown-body {
  max-height: 26.04167vw;
  opacity: 1;
}

.priceP1 {
  color: #adabb2;
  font-size: 1vw;
  font-family: "Neue Machina";
  font-style: normal;
}

.priceP2 {
  color: #ffffff;
  font-size: 1vw;
  font-family: "Neue Machina";
  font-style: normal;
}

.priceN {
  color: #ffffff;
  font-size: 1vw;
  font-family: "Neue Machina";
  font-style: normal;
  margin: 0 0.5vw;
}

.priceNCrossed {
  display: inline;
  color: #ffffff;
  font-style: inherit;
  font-size: 0.9vw;
  font-family: "Neue Machina";
  font-style: normal;
  margin-left: 0.5vw;
  text-decoration: line-through;
}

.purchase {
  border: none;
  color: rgb(51, 51, 51);
  font-family: "Neue Machina";
  font-weight: 600;
  width: 11vw;
  height: 3vw;
  font-size: 0.9vw;
  border-radius: 1.5vw;
  margin-left: 1vw;
  cursor: pointer;
  /* text-shadow: -0.01vw 0.01vw 0px rgba(0, 0, 0, 1),
    0.01vw 0.01vw 0px rgba(0, 0, 0, 1), 0.01vw -0.01vw 0px rgba(0, 0, 0, 1),
    -0.01vw -0.01vw 0px rgba(0, 0, 0, 1); */
}

.priceCont {
  display: flex;
  justify-content: end;
  align-items: center;
}

.dropdown-icon {
  margin: 0 0.8vw;
  margin-right: 2.1vw;
  font-size: 1vw;
  cursor: pointer;
  width: 1vw;
  height: 1vw;
}

.dropImg1_mob,
.dropImg2_mob,
.dropImg3_mob {
  display: none;
}

.purchaseCont .dropdown-header {
  width: 47vw;
}

.dropdown-icon-mobile {
  display: none;
}

.mobile {
  display: none;
}

.answer2 {
  display: none;
}

@media (max-width: 1025px) {
  .dropdown.open .dropdown-body {
    max-height: 40vw;
  }

  .dropdown-body {
    width: 90vw;
    height: 55 vw;
  }

  .dropdown-question1 {
    margin-left: 1.2vw;
    margin-right: 0.5vw;
    font-size: 1.9vw;
    line-height: 1.5vw;
  }

  .dropdown-question2 {
    font-size: 1.2vw;
    margin-right: -4vw;
    line-height: 1.6vw;
  }

  .dropdown {
    word-spacing: 0.01vw;
    line-height: normal;
  }

  .priceP1 {
    margin: 0;
    font-size: 1.2vw;
  }

  .priceP2 {
    margin: 0;
    font-size: 1.2vw;
  }

  .priceN {
    margin: 0;
    font-size: 1.2vw;
    margin: 0 0.5vw;
  }

  .priceNCrossed {
    margin: 0;
    font-size: 1vw;
    margin-left: 0.3vw;
  }

  .purchase {
    width: 13vw;
    height: 3.5vw;
    font-size: 1.1vw;
    line-height: 1.5vw;

    margin-left: 0.8vw;
    cursor: pointer;
    text-shadow: none;
  }

  .dropdown-header {
    border: 0.5px solid #9f3dff;
    height: 5vw;
    width: 90vw;
    border-radius: 1vw;
    grid-template-columns: 8fr 7fr;
    margin-top: 1.1vw;
    margin-bottom: 1.1vw;
  }
}

@media (max-width: 431px) {
  .answer1 {
    display: none;
  }

  .dropname {
    display: flex;
    flex-wrap: wrap;
  }

  .question-container {
    margin: 0;
    padding: 0;
    margin-top: 6vw;
  }

  .dropdown {
    margin-left: 3.5vw;
    margin-top: 4vw;
  }

  .dropdown.open .dropdown-body {
    max-height: fit-content;
    display: flex;
    margin: 0 auto;
  }

  .dropname {
    line-height: auto;
  }

  .dropdown-header {
    margin: 0;
    display: flex;
    flex-direction: column;
    border: 0.3vw solid #9f3dff;
    min-height: 25vw;
    height: fit-content;
    width: 89vw;
    border-radius: 5vw;
    margin-top: 1.1vw;
    margin-bottom: 1.1vw;
    align-items: start;
    justify-content: start;
    padding: 2vw;
    padding-top: 4vw;
  }

  .dropdown-body {
    display: flex;
    width: 90vw;
    height: fit-content;
    margin-left: -3vw;

  }

  .dropdown-question1 {
    display: flex;
    margin-left: 1.8vw;
    margin-right: 0.5vw;
    font-size: 5vw;
    line-height: normal;
    align-self: center;
    margin-right: 1vw;
  }

  .dropdown-question2 {
    font-size: 3vw;
    margin-right: 0;
    margin-left: 1vw;
    line-height: normal;
    align-self: center;
  }

  .priceCont {
    margin-top: 1.2vw;
    margin-bottom: 2.5vw;
    margin-left: 1.2vw;
  }

  .priceP1 {
    margin: 0;
    font-size: 3vw;
  }

  .priceP2 {
    margin: 0;
    font-size: 3vw;
  }

  .priceN {
    margin: 0;
    font-size: 3vw;
    margin: 0 0.5vw;
  }

  .priceNCrossed {
    margin: 0 1vw;
    font-size: 2vw;
    margin-left: 0.3vw;
    line-height: normal;

  }

  .purchase {
    display: none;
  }

  .dropdown-icon {
    display: none;
  }

  .mobile {
    display: flex;
    align-items: stretch;
    width: 89vw;
  }

  .purchase-mobile {
    display: block;
    border: none;
    color: rgb(51, 51, 51);
    font-family: "Neue Machina";
    font-weight: 400;
    width: 42vw;
    height: 11vw;
    font-size: 4vw;
    border-radius: 6vw;
    cursor: pointer;
    margin-bottom: 1vw;
    padding: 3vw;
    margin-left: 1.1vw;
  }

  .dropdown-icon-mobile {
    display: inline;
    margin-right: 2.1vw;
    cursor: pointer;
    width: 3.5vw;
    font-size: 0.9vw;
    height: 2.3vw;
    padding: 0;
    fill: black;
    font-size: 3.8vw;
    margin-left: 2vw;
  }

  .secondB {
    background-color: #5e5e5e00;
    border: solid 0.2vw rgb(255, 255, 255);
    color: #fff;
    margin-left: 3vw;
  }

  .shit {
    color: #fff;
  }

  .answer2 {
    display: flex;
    color: #fff;
    font-size: 2vw;
  }
}