.footer {
  margin-top: 7vw;
  margin-bottom: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .icon {
  margin-left: 1.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.44895833vw;
  height: 2.44895833vw;
}

.footer .icon-tg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.44895833vw;
  height: 2.44895833vw;
}

.footer .downside {
  align-items: center;
  justify-content: center;
  display: grid;
}

.footer .e-mail {
  color: #e3dede;
  text-align: center;
  font-family: "Neue Machina";
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 700;
  margin-top: 1.7vw;
}

.footer .rights {
  color: #87868c;
  font-family: "Neue Machina";
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 1vw;
}
.rights p {
  margin-top: 0.06vw;
  margin-bottom: 0.05vw;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5vw;
}

.social {
  align-items: center;
  justify-content: center;
}

.footer-item:hover {
  cursor: pointer;
}

.social {
  display: flex;
}

.icon-hover {
  transition: 0.3s ease;
  cursor: pointer;
}

.icon-hover:hover {
  opacity: 0.8;
}
.aaio {
  display: flex;
  justify-content: end;
}
.aaio img{
  display: flex;
  width: 4.5vw;
  height: auto;
}
@media (max-width: 1025px) {
  .footer .icon {
    margin-left: 3vw;
    width: 5vw;
    height: 5vw;
  }

  .footer .icon-tg {
    width: 5vw;
    height: 5vw;
  }

  .footer .e-mail {
    font-size: 2vw;
    margin-top: 3vw;
  }

  .footer .rights {
    font-size: 1vw;
    margin-top: 0.501vw;
  }
  .aaio img{
    width: 12vw;
  }
}
@media (max-width: 431px) {
  .footer{
    margin-top: 20vw;
  }
  .footer .icon {
    margin-left: 3vw;
    width: 12vw;
    height: 12vw;
  }

  .footer .icon-tg {
    width: 12vw;
    height: 12vw;
  }

  .footer .e-mail {
    font-size: 5vw;
    margin-top: 4vw;
  }

  .footer .rights {
    font-size: 3vw;
    margin-top: 1vw;
  }
  .footer .rights p {
    margin-top: 2.8vw;
}
.aaio img{
  width: 20vw;
}


}
