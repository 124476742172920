.carousellCont {
    width: 90vw;
    height: fit-content;
    margin: 2vw 1vw;
}
.carousellCont .mobIng {
    width: 100vw;
}

.carousellCont .mob11 {
}
.carousellCont .mob12 {
}
.carousellCont .mob13 {
}

.buttonContMob {
    z-index:1;
    display: flex;
    justify-content: center;
    margin-top: -11vw;
}

.buttonContMob .mobButtonS {
    z-index:2;
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    border: 0.35vw solid ;
    outline: none;
    margin: 1vw;
    cursor: pointer;
}

.slick-slider {
    margin-left: -1vw;
    z-index: 1;
}