.question-container {
  display: block;
  background-repeat: no-repeat;
  background-attachment: local;
  margin: 0 8%;
  margin-top: 6vw;
  justify-content: center;
}
.question-container-align {
  align-items: center;
  display: grid;
  margin: auto;
  overflow: hidden;
  
}
.dropImg1{
  width: 80vw;
  height: 27vw;
}
.dropImg2{
  width: 80vw;
  height: 21vw;
}
.dropImg3{
  width: 80vw;
  height: 33vw;
}

@media (max-width: 1025px){
  .dropImg1{
    width: 92vw;
    height: 35vw;
    margin-top: -2vw;
  }
  .dropImg2{
    width: 89vw;
    height: 35vw;
    margin-top: -5.3vw;
    margin-bottom: -7vw;
  }
  .dropImg3{
    width: 89vw;
    height: 35vw;
    margin-top: 1vw;
  }
  .question-container {
    margin: 0 3%;
    margin-top: 6vw;
  }

  
  .dropImg1,
  .dropImg2,
  .dropImg3 {
    display: none;
  }
  .dropImg1_mob,
  .dropImg2_mob,
  .dropImg3_mob {
    display: flex;
  }
  .dropImg1_mob{
    width: 110vw;
    height: 50vw;
    margin-top: -5vw;
  }
  .dropImg2_mob{
    width: 110vw;
    height: 30vw;
  }
  .dropImg3_mob{
    width: 110vw;
    height: 39vw;
  }
}
@media (max-width: 431px){
  .question-container {
    margin: 0 ;
    margin-top: 0;
  }

}
