/* Card.css */
.cardCont {
    height: fit-content;
    border-radius: 4vw;
    margin-bottom: 3vw;
}

.cardHeader {
    background-color: #7C3EDC;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60vw;
    height: 12vw;
    border-radius: 2vw;
    font-size: 4.1vw;
    margin-top: 1.1vw;
    margin-bottom: 4.1vw;
}

.cardText {
    font-size: 14px;
    line-height: 8vw;
    color: #b2b2b2a5;
}

.cardLesson {
    margin-right: 4vw;
    color: #ffffff;
}